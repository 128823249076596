<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Pusher from "pusher-js";
import Swal from "sweetalert2";
import API from "@/api.js";
import Sound from "@/assets/sounds/detection.mp3";
export default {
  name: "app",
  data() {
    return {
      pusher: null,
      defaultLayout: "default",
      notificationSound: new Audio(Sound),
      projects: null
    };
  },
  computed: {
    layout() {
      let layout = `${this.$route.meta.layout || this.defaultLayout}-layout`;
      return layout;
    }
  },
  methods: {
    async getProjects() {
      const res = await API.get(`projects?users=${this.user.id}`);
      this.projects = res.data;
      
    },
    violationDetected(data) {
      let isUserProject = this.projects.find(project => project.slug == data.work.slug)
      console.log(isUserProject)
      if (
        this.$route.name != "Auth" &&
        this.$route.name != "Signup" &&
        this.$route.name != "Forget" &&
        this.$route.name != "Reset" && 
        this.projects && isUserProject
      ) {
        // this.latestDetection = data;
        // if (!this.muteSound) {
        this.notificationSound.play()
        // }

        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "X",
          buttonsStyling: false,
          customClass: {
            icon: "toast-icon-small",
            cancelButton: "btn btn-link btn-sm",
            container: "toast-offset",
            title: "toast-title"
          },
          timer: 5000,
          timerProgressBar: true,
          icon: "success",
          title: `New violation on ${
            data.work.name
          } \n(${data.name}) ${isUserProject.name}`,
          text: `${data.name}`,
          html: `<a class='btn btn-dark btn-sm' href='./#/projects/${data.work.slug}/cameras/${data.work.id}'>View</a>`
        });
      }
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if(this.user){
      this.getProjects()
    }
    
    this.pusher = new Pusher("546809d823ede5cbc2d7", {
      cluster: "ap1",
      forceTLS: true
    });

    this.pusher.connection.bind("connected", () => {
      console.log("Pusher connected");
    });

    const channel = this.pusher.subscribe("aisc");

    channel.bind("violation", data => {
      this.violationDetected(data);
    });
    
    // this.violationDetected(
    //   JSON.parse(
    //     '{"id":28069,"name":"No Gas Mask, No Welding Mask, No Earmuff, No Helmet","image":"https://prsb-socket.inference.asia/1617020249_1968.png","count":{"No Helmet":1,"No Earmuff":1,"No Gas Mask":1,"No Welding Mask":1},"isCounted":false,"isFalsePositive":false,"countFalse":null,"timestamp":"1617020249","subwork":{"id":2193},"work":{"id":"7"},"published_at":"2021-03-29T12:17:29.974Z","created_at":"2021-03-29T12:17:29.978Z","updated_at":"2021-03-29T12:17:29.987Z","interventions":[]}'
    //   )
    // )
  }
};
</script>
<template>
  <div class="w-100">
    <div
      v-if="!recordings"
      class="col-12 d-flex justify-content-center align-items-center py-4"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div class="w-100" v-if="recordings">
      <div
        class="w-100 record-item border-bottom d-flex justify-content-between align-items-center px-2"
        :class="{
          'bg-recording-selected':
            selectedRecording && selectedRecording == recording,
          'bg-white':
            !selectedRecording || selectedRecording.id != recording.id,
        }"
        v-for="recording in recordings"
        :key="recording.name"
      >
        <div
          class="w-100 h-100 py-2 d-flex justify-content-start align-items-center"
        >
          <img class="mr-2 h-100" src="@/assets/images/mp4.png" alt="" />
          <p class="m-0 recording-title">
            {{ recording.name }}
          </p>
        </div>
        <div class="d-flex h-100 align-items-center" style="color: #00a19c">
          <font-awesome-icon
            :icon="['fas', 'download']"
            @click="downloadClick(recording)"
            class="align-self-right mr-3 cursor-pointer"
          />
          <font-awesome-icon
            :icon="['fas', 'play']"
            @click="recordClick(recording)"
            v-if="getOS() != 'iOS'"
            class="align-self-right mr-2 cursor-pointer"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
export default {
  props: ["subwork", "selectedRecording"],
  data() {
    return {
      recordings: null,
      isLoadinng: false
    };
  },
  watch: {
    subwork() {
      this.getRecordings();
    }
  },
  methods: {
    async getRecordings() {
      this.recordings = null;
      await axios
        .get("https://recordings.inference.asia/subworks/" + this.subwork)
        .then(res => {
          console.log(res.data);
          this.recordings = res.data;
        });
    },
    recordClick(record) {
      this.$emit("recordSelected", record);
    },
    async downloadClick(record) {
      console.log(record)
      window.location.href = record.download_url
      // window.open(record.download_url, "_blank"); 
      // const anchor = document.createElement("a");
      // anchor.href = record.download_url;
      // anchor.target = "_blank";
      // anchor.click();
    },
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
    }
  },
  mounted() {
    console.log(this.subwork);
    console.log(this.getOS())
    this.getRecordings();
  }
};
</script>
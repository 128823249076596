<template>
	<div class="page-default">
	<div id="map" class="h-100"></div>
	</div>
</template>
<script>
import mapboxgl from "mapbox-gl";
import API from "@/api.js";

export default {
	data() {
		return {
			user: null,
			projectOptios: [],
			selectedProject: null
		};
	},
	
	methods: {		
		initMap() {
			return new Promise((resolve) => {
				mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
	
				this.map = new mapboxgl.Map({
					container: "map",
					style: "mapbox://styles/mapbox/satellite-streets-v11",
					center: [102.138148, 2.262246],
					zoom: 8
				});
		
				this.map.on("load", () => {
					this.map.loadImage("/marker.png", (err, image) => {
						if (err) {
							console.log(err);
							return;
						}	
						this.map.addImage("custom-marker", image);
						resolve();					
					});							
				});
			});
		}		
	},
	
	async mounted() {
		this.user = JSON.parse(localStorage.getItem("user"));		
		await this.initMap();
		
		const res = await API.get(`projects?users=${this.user.id}`);
		const projects = res.data;
		
		this.map.addSource("points", {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: projects.map((project) => {
					return {						
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: [project.lng, project.lat]
						},
						properties: {
							title: project.name,
							slug: project.slug
						}
					}
				})
			}
		});
		
		this.map.addLayer({
			id: "points",
			type: "symbol",
			source: "points",
			layout: {
				"icon-image": "custom-marker",
				"text-field": ["get", "title"],
				"text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				"text-offset": [0, 1.5],
				"text-anchor": "top",
				"icon-allow-overlap": true,
				'text-allow-overlap': true,	
				'text-size': 14
			},
			paint: {
				"text-color": "#ffffff",
				"text-halo-width": 1,
				"text-halo-color": "#000",
				"text-halo-blur": 2
			}
		});
		
		this.map.on("mouseenter", "points", () => {
			this.map.getCanvas().style.cursor = "pointer";
		});

		this.map.on("mouseleave", "points", () => {
			this.map.getCanvas().style.cursor = "";
		});
		
		this.map.on("click", "points", (e) => {
			const props = e.features[0].properties;
			
			this.$router.push({ 
				name: "Home", 
				params: { 
					slug: props.slug 
				} 
			});
		});
		
		if (projects.length > 1) {
			const bounds = new mapboxgl.LngLatBounds();
			
			projects.forEach((project) => {
				bounds.extend([project.lng, project.lat]);
			})
			
			this.map.fitBounds(bounds, {
				padding: 60,
				easing: (t) => {
					return t;
				}
			});	
		}
		else {
			this.map.flyTo({
				center: [projects[0].lng, projects[0].lat],
				essential: true
			});
		}		
	}
};
</script>
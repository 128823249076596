<template>
  <div class="form-group">
    <label :for="input.id" class="font-weight-medium default">
      {{ input.label.replace("_", " ") }}
    </label>
    <select :id="input.id" class="custom-select" v-model="model" data-live-search="true">
      <option :value="null" :disabled="!input.includeNull">{{ input.placeholder }}</option>
      <option v-for="option in options" :key="option.id" :value="option.id">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "select-input-component",
  props: ["input", "options"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
    "input.model": {
      handler() {
        this.model = this.input.model;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-default shadow-sm py-0">
    <a class="navbar-brand" href="#" @click.prevent="goTo('Projects')">
      <img src="@/assets/images/logo.png" class="logo" />
    </a>
    <div class="d-flex align-items-center py-2 nav-item" v-if="this.$route.name != 'ChangePassword'">
      <div class="nav-divider h-100"></div>
    </div>
    <div class="ml-2" v-if="this.$route.name != 'admin.users' && this.$route.name != 'ChangePassword'">
      <select
        class="custom-select custom-select-sm nav-select"
        v-model="selectedProject"
        @change="projectChange()"
      >
        <option :value="null" disabled>Select a Project</option>
        <option
          v-for="option in projectOptios"
          :key="option.name"
          :value="option"
        >
          {{ option.name }}
        </option>
        <!-- <option value="null">DELIMA Project</option> -->
      </select>
    </div>

    <button
      class="navbar-toggler border-0 ml-auto"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto w-100 ml-2">
        <li
          class="nav-item main justify-content-start justify-content-md-center"
          :class="{
            active:
              isActiveNav('Projects') ||
              isActiveNav('Home') ||
              isActiveNav('Camera')||
              isActiveNav('Analytics')||
              isActiveNav('Report')||
              isActiveNav('Activity'),
          }"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Projects')"
            >Projects
          </a>
        </li>
        
        <li
          class="nav-item main justify-content-start justify-content-md-center"
          :class="{ active: isActiveNav('MainAnalytics') }"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('MainAnalytics')"
            >Analytics
          </a>
        </li>
        <!-- <li
          class="nav-item main justify-content-start justify-content-md-center"
          :class="{ active: isActiveNav('Activity') }"
          v-if="(userIsAdmin || userIsManagement || !userIsOperator) &&
                this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Activity')"
            >Activity
          </a>
        </li>
        <li
          class="nav-item main justify-content-start justify-content-md-center"
          :class="{ active: isActiveNav('Report') }"
          v-if="this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Report')"
            >Report
          </a>
        </li> -->
        <!-- <li
          class="nav-item main justify-content-start justify-content-md-center"
          :class="{ active: isActiveNav('admin.users') }"
          v-if="userIsAdmin"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('admin.users')"
            >Admin
          </a>
        </li> -->
        <li class="d-block d-md-none">
          <div class="dropdown-divider"></div>
        </li>
        <li
          class="nav-item main justify-content-start justify-content-md-center active d-block d-md-none"
        >
          <a
            class="nav-link stretched-link line-height-1"
            href="#"
            @click.prevent="logout()"
          >
            <img
              class="user mr-2 d-inline-block align-text-bottom"
              src="@/assets/images/user.jpg"
            />
            <div class="d-inline-block">
              <p class="mb-0">{{ user && user.profile.name }}</p>
              <small>Sign Out</small>
            </div>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-2 d-none d-md-block">
        <li class="nav-item" id="signout">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle p-0 d-flex justify-content-start align-items-center text-white"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img class="user mr-2" src="@/assets/images/user.jpg" />
              <div>
                <!-- <p v-if="user" class="font-smaller m-0">login as</p> -->
                <p v-if="user" class="font-smaller font-weight-bold m-0 mr-2">
                  {{ user && user.profile.name }}
                </p>
              </div>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <router-link v-if="userIsAdmin" to="/admin/users" class="dropdown-item">
                <small>
                  <!-- <svg height="12pt" viewBox="-64 0 512 512" width="12pt" xmlns="http://www.w3.org/2000/svg"><path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"/><path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"/></svg> -->
                   Admin
                </small>
              </router-link>
              <router-link to="/change-password" class="dropdown-item">
                <small>
                  <svg height="12pt" viewBox="-64 0 512 512" width="12pt" xmlns="http://www.w3.org/2000/svg"><path d="m336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"/><path d="m304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"/></svg>
                   Change Password
                </small>
              </router-link>
              <a class="dropdown-item" href="#" @click.prevent="logout()">
                <small>
                  <svg height="12pt" viewBox="0 0 511 512" width="12pt" xmlns="http://www.w3.org/2000/svg"><path d="m361.5 392v40c0 44.113281-35.886719 80-80 80h-201c-44.113281 0-80-35.886719-80-80v-352c0-44.113281 35.886719-80 80-80h201c44.113281 0 80 35.886719 80 80v40c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20v-40c0-22.054688-17.945312-40-40-40h-201c-22.054688 0-40 17.945312-40 40v352c0 22.054688 17.945312 40 40 40h201c22.054688 0 40-17.945312 40-40v-40c0-11.046875 8.953125-20 20-20s20 8.953125 20 20zm136.355469-170.355469-44.785157-44.785156c-7.8125-7.8125-20.476562-7.8125-28.285156 0-7.8125 7.808594-7.8125 20.472656 0 28.28125l31.855469 31.859375h-240.140625c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h240.140625l-31.855469 31.859375c-7.8125 7.808594-7.8125 20.472656 0 28.28125 3.90625 3.90625 9.023438 5.859375 14.140625 5.859375 5.121094 0 10.238281-1.953125 14.144531-5.859375l44.785157-44.785156c19.496093-19.496094 19.496093-51.214844 0-70.710938zm0 0"/></svg>
                  Sign Out
                </small>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
import API from "@/api.js";

export default {
  data() {
    return {
      user: null,
      projectOptios: [],
      selectedProject: null
    };
  },

  watch: {
    $route: {
      handler() {
        $(".navbar-collapse").removeClass("show");
        if (this.$route.name != "Projects" && this.$route.name != "MainAnalytics") {
          var pslug = this.$route.params.slug
          this.selectedProject = this.projectOptios.find((project) =>  project.slug == pslug );
        } else {
          this.selectedProject = null;
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    userIsAdmin() {
      return this.user && this.user.role && this.user.role.name == "Admin";
    },
    userIsOperator() {
      return this.user && this.user.role && this.user.role.name == "Operator";
    },
    userIsManagement() {
      return this.user && this.user.role && this.user.role.name == "Management";
    }
  },

  methods: {
    projectChange(){
      this.goTo("Home")
    },
    isActiveNav(name) {
      return this.$route.name == name;
    },

    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      // this.$router.push({
      //   name: "Auth"
      // });
      window.location.reload()
    },

    goTo(name) {
      let prop = { name: name, params: {slug : null} }
      if(name == 'Home' || name == 'Analytics' || name == 'Activity' || name == 'Report'){
        prop.params.slug = this.selectedProject.slug 
      }

      if (this.$route.name != name || this.$route.params != prop.params)  this.$router.push(prop)
    },

    async getProjects() {
      const res = await API.get(`projects?users=${this.user.id}`);
      this.projectOptios = res.data;
      if (this.$route.name != "Projects" && this.$route.name != "MainAnalytics") {
        var pslug = this.$route.params.slug
        this.selectedProject = this.projectOptios.find((project) =>  project.slug == pslug );
      } else {
        this.selectedProject = null;
      }
    }
  },

  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if(this.user){
      this.getProjects()
      this.projectOptios = this.user.projects
    }
  }
};
</script>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import './assets/css/style.scss'
import Default from './layouts/Default.vue'
import NoNavbar from './layouts/NoNavbar.vue'
import Admin from './layouts/Admin.vue'
import VCalendar from 'v-calendar';

import {
  library
} from '@fortawesome/fontawesome-svg-core'

import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleRight,
  faArrowLeft,
  faBriefcase,
  faCamera,
  faCloudDownloadAlt,
  faCogs,
  faDownload,
  faEdit,
  faExclamationCircle,
  faExclamationTriangle,
  faFileDownload,
  faGlobeAsia,
  faHardHat,
  faList,
  faListAlt,
  faListUl,
  faMapMarkerAlt,
  faPlay,
  faRecordVinyl,
  faTimes,
  faTrashAlt,
  faUserCheck,
  faUserFriends,
  faUserShield,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons'

import {
  faCalendarAlt,
  faChartBar,
  faEye,
  faFileAlt,
  faFileImage,
  faFileVideo,
  faIdCard
} from '@fortawesome/free-regular-svg-icons'

import {
  faElementor
} from '@fortawesome/free-brands-svg-icons'

Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('default-layout', Default)
Vue.component('no-navbar-layout', NoNavbar)
Vue.component('admin-layout', Admin)

library.add(faDownload)
library.add(faCamera)
library.add(faMapMarkerAlt)
library.add(faCalendarAlt)
library.add(faListAlt)
library.add(faExclamationTriangle)
library.add(faUserFriends)
library.add(faArrowLeft)
library.add(faVolumeUp)
library.add(faChartBar)
library.add(faEye)
library.add(faHardHat)
library.add(faGlobeAsia)
library.add(faBriefcase)
library.add(faFileAlt)
library.add(faFileImage)
library.add(faIdCard)
library.add(faFileVideo)
library.add(faAngleRight)
library.add(faFileDownload)
library.add(faPlay)
library.add(faCloudDownloadAlt)
library.add(faDownload)
library.add(faListUl)
library.add(faTimes)
library.add(faElementor)
library.add(faRecordVinyl)
library.add(faCogs)
library.add(faUserCheck)
library.add(faUserShield)
library.add(faTrashAlt)
library.add(faEdit)
library.add(faList)
library.add(faCalendarAlt)
library.add(faExclamationCircle)

Vue.use(VCalendar, {});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

window.location = 'https://aisc.tv'
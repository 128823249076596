<template>
  <div class="ranking">
    <div class="frame-2">
      <div class="rank-container">
        <p class="rank m-0">{{index+1}}</p>
      </div>
      <img
        alt=""
        class="num-1kb-ts8-pc-400x400-1 rounded-circle"
        :src="getImage()"
      />
      <div class="frame-1">
        <p class="name">{{rank.user.profile.name}}</p>
        <p class="position mb-1">{{rank.user.profile.position}}, {{rank.user.profile.department}}</p>
        <p class="name">{{rank.intervene}} interventions</p>
      </div>
    </div>
    <div class="rank-border"></div>
  </div>
</template>

<script>
import gravatar from 'gravatar'
export default {
  name: "Ranking",
  props: ['rank','index'],
  methods:{
      getImage(){
          let url = gravatar.url(this.rank.user.email)
          return `${url}?d=mp`
      }
  }
};
</script>